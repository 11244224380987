
/* eslint-disable */
import { defineComponent } from 'vue';

export default defineComponent({
  components: {
  },
  setup() {
    //#region Data
    
    //#endregion
    
    //#region Methods
    
    //#endregion
    
    return {
      //#region Data
      
      //#endregion
      
      //#region Methods
      
      //#endregion
    }
  },
})
